<template>
  <vue-draggable-resizable
    :active="false"
    :resizable="false"
    :draggable="true"
    :w="280"
    :h="100"
    :x="leftFromStore"
    :y="topFromStore"
    @dragStop="(left, top) => onDragStop(left, top)"
    :parent="false"
  >
    <v-card
      class="d-flex flex-column justify-space-between unit-card pa-1"
      :style="{ 'background-color': unitStatus.status.colorBackground }"
    >
      <div class="d-flex flex-column unit-name">
        <v-row no-gutters>
          <v-col>
            <span>{{ unitStatus.name }}</span>
          </v-col>
          <v-col md="auto"> </v-col>
        </v-row>
        <span class="unit-description text-uppercase">{{
          unitStatus.description
        }}</span>
      </div>
      <v-row no-gutters>
        <v-col class="status-age text-uppercase" style="align-self: flex-end">
          <FromNow :date="unitStatus.statusReceived" />
        </v-col>
        <v-col style="align-self: flex-end">
          <span class="status-name text-uppercase text-right">
            <v-select
              hide-details
              class="status-select"
              density="compact"
              variant="plain"
              v-model="selectedStatus"
              :items="statuses"
              item-title="name"
              item-value="id"
              return-object
              @update:modelValue="updateStatus"
              @click.stop
              :loading="updating"
            ></v-select>
          </span>
        </v-col>
      </v-row>
    </v-card>
  </vue-draggable-resizable>
</template>
<style scoped lang="sass">
.status-select .v-select__selection 
  width: 100%
  justify-content: flex-end
  align-content: flex-end
  border-color: green

.status-select .v-select__selection .v-select__selection-text 
  font-weight: bold
  font-size: small

.status-select .v-field__input 
  padding-right: 0
  justify-content: flex-end
  border-width: 5px

.status-select .v-field__append-inner
  margin-left: -6px

</style>
<script>
import FromNow from "./common/FromNow.vue";
import _ from "lodash";
import debug from "debug";

export default {
  components: { FromNow },
  props: {
    unitStatus: Object
  },
  data() {
    return {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
      updating: false
      //localUnitStatus: { ...this.unitStatus }
    };
  },
  computed: {
    statuses() {
      return this.$store.getters["status/status"];
    },
    selectedStatus: {
      get() {
        return this.unitStatus.status;
      },
      set(value) {
        this.log("value", value);
        //Handled in updateStatus funtion, not directly!
      }
    },
    topFromStore() {
      let p = this.$store.getters["localStorage/situationCardPositions"];
      let top = _.find(p, x => x.unitId === this.unitStatus.id);
      if (!top) {
        return 0;
      }
      return top.top;
    },
    leftFromStore() {
      let p = this.$store.getters["localStorage/situationCardPositions"];
      let top = _.find(p, x => x.unitId === this.unitStatus.id);
      if (!top) {
        return 0;
      }
      return top.left;
    }
  },
  methods: {
    log: debug("ColoredUnitCard"),
    async updateStatus(newStatus) {
      let me = this;
      var newUnitStatus = { ...this.unitStatus, status: newStatus };

      try {
        this.updating = true;
        await me.$store.dispatch("units/changeStatus", newUnitStatus);
        this.updating = false;
        me.$toast({
          type: "success",
          message:
            `Yksikön '` +
            newUnitStatus.name +
            `' tilaksi on muutettu '` +
            newUnitStatus.status.name +
            `'`
        });
      } catch (err) {
        this.updating = false;
        this.$error(err);
      }
    },
    onDragStop(left, top) {
      this.log("dragstop", left, top);
      this.top = top;
      this.left = left;

      let cardPos = {
        unitId: this.unitStatus.id,
        left: this.left,
        top: this.top
      };
      this.$store.dispatch("localStorage/save", cardPos);
    }
  }
};
</script>
