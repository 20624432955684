<template>
  <v-app id="inspire">
    <toast></toast>
    <SignalRAlert></SignalRAlert>
    <v-app-bar v-if="$auth.state.isAuthenticated" color="primary" prominent>
      <template v-slot:prepend>
        <v-img
          class="app-bar-logo"
          alt="KRISTA logo"
          src="@/assets/krista_logo_neg.png"
          :width="160"
        />
      </template>
      <v-spacer></v-spacer>
      <MissionCreator inAppBar />
      <NoteCreator />
    </v-app-bar>
    <v-navigation-drawer
      v-if="$auth.state.isAuthenticated"
      v-model="drawer"
      expand-on-hover
      rail
      permanent
    >
      <v-list density="compact" nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :title="item.title"
          link
          :to="item.target"
          :prependIcon="item.icon"
        >
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list density="compact" nav>
          <v-list-item
            link
            @click="logout"
            prependIcon="mdi-logout"
            title="Kirjaudu ulos"
          ></v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>

    <confirm></confirm>
    <loading :show="isLoading"></loading>
  </v-app>
</template>
<style lang="sass" scoped>
.app-bar-logo 
  margin-left: 20px

</style>
<script>
import { watch } from "vue";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import { getInstance } from "@/auth/index";
import appInsights from '@/services/applicationInsights';
import Toast from "./components/common/Toast.vue";
import SignalRAlert from "./components/common/SignalRAlert.vue";
import Confirm from "./components/common/Confirm.vue";
import NoteCreator from "./views/NoteCreator.vue";
import MissionCreator from "./views/MissionCreator.vue";
import Loading from "./components/common/Loading.vue";

export default {
  name: "App",

  components: {
    Toast,
    Confirm,
    Loading,
    NoteCreator,
    MissionCreator,
    SignalRAlert
  },

  props: {
    source: String
  },
  data: () => ({
    hubConnection: null,
    drawer: null,
    items: [
      {
        icon: "mdi-focus-field",
        title: "Tilannekuva",
        target: "/situation"
      },
      {
        icon: "mdi-hospital-building",
        title: "Resurssitaulu",
        target: "/resource-table"
      },
      {
        icon: "mdi-bell-outline",
        title: "Tehtävät",
        target: "/missions"
      },
      { icon: "mdi-history", title: "Aikajana", target: "/events" },
      {
        icon: "mdi-message-flash-outline",
        title: "VIRVE-viestin lähetys",
        target: "/alert"
      },
      { icon: "mdi-cog", title: "Asetukset", target: "/settings" }
    ]
  }),
  computed: {
    isLoading() {
      return this.$store.getters["app/isLoading"];
    }
  },
  methods: {
    async connectHub() {
      let me = this;
      if (import.meta.env.VITE_SIGNALR_URL) {
        me.hubConnection = new HubConnectionBuilder()
          .withUrl(import.meta.env.VITE_SIGNALR_URL, {
            accessTokenFactory: async () => {
              let authService = getInstance();
              if (authService == null) {
                return null;
              }
              let token = await authService.getTokenSilently({
                authorizationParams: {
                  audience: import.meta.env.VITE_AUTH0_AUDIENCE
                }
              });
              return token;
            },
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
          })
          .withAutomaticReconnect([0, 2000, 5000, 5000, 10000, null])
          .build();

        me.hubConnection.onreconnecting(error => {
          console.warn("SignalR reconnecting...", error);
          appInsights.trackEvent({ name: 'SignalRReconnecting', properties: { error: error, connectionId: me.hubConnection.connectionId } });
          this.$store.commit("app/set", [
            "signalRState",
            me.hubConnection.state
          ]);
        });

        me.hubConnection.onreconnected(connectionId => {
          console.log("SignalR reconnected", connectionId);
          appInsights.trackEvent({ name: 'SignalRReconnected', properties: { connectionId: connectionId } });
          this.$store.commit("app/set", [
            "signalRState",
            me.hubConnection.state
          ]);
        });

        me.hubConnection.onclose(error => {
          console.log("SignalR closed", error);
          appInsights.trackEvent({ name: 'SignalRClosed', properties: { error: error, connectionId: me.hubConnection.connectionId } });
          this.$store.commit("app/set", [
            "signalRState",
            me.hubConnection.state
          ]);
        });

        me.hubConnection.on("unit-status-update", async data => {
          appInsights.trackEvent({ name: 'SignalRReceive', properties: { event: 'unit-status-update', data: data } });
          console.log("SignalR: unit-status-update", data);
          try {
            this.$store.dispatch("units/updateStatus", data);
          } catch (err) {
            console.error(err);
          }
        });

        me.hubConnection.on("log-entry", async data => {
          appInsights.trackEvent({ name: 'SignalRReceive', properties: { event: 'log-entry', data: data } });
          console.log("SignalR: log-entry", data);
          try {
            this.$store.commit("events/addReceivedEvent", data);
          } catch (err) {
            console.error(err);
          }
        });

        me.hubConnection.on("mission-delete", async data => {
          appInsights.trackEvent({ name: 'SignalRReceive', properties: { event: 'mission-delete', data: data } });
          console.log("SignalR: mission-delete", data);
          try {
            this.$store.commit("missions/deleteMission", data.id);
          } catch (err) {
            console.error(err);
          }
        });

        me.hubConnection.on("mission", async data => {
          appInsights.trackEvent({ name: 'SignalRReceive', properties: { event: 'mission', data: data } });
          console.log("SignalR: mission", data);
          try {
            this.$store.commit("missions/addMission", data);
          } catch (err) {
            console.error(err);
          }
        });

        me.hubConnection.on("resource-change", async data => {
          appInsights.trackEvent({ name: 'SignalRReceive', properties: { event: 'resource-change', data: data } });
          console.log("SignalR: resource-change", data);
          try {
            this.$store.dispatch("resources/load");
          } catch (err) {
            console.error(err);
          }
        });

        try {
          await me.hubConnection.start();
        } catch (e) {
          console.log("Error while connecting hub", e);
          appInsights.trackEvent({ name: 'SignalRErrorOnStart' });
          appInsights.trackException({ exception: e });
          //TODO error info to user
        }
        appInsights.trackEvent({ name: 'SignalRStarted', properties: { connectionId: me.hubConnection.connectionId } });
        this.$store.commit("app/set", ["signalRState", me.hubConnection.state]);
      }
    },
    // Log the user out
    logout() {
      let me = this;

      this.$store.commit("app/set", ["isLoggingOut", true]);

      if (me.hubConnection) {
        me.hubConnection.stop();
      }

      this.$store.commit("app/set", ["signalRState", me.hubConnection.state]);

      this.$auth.logout({
        returnTo: window.location.origin + "/logout-callback"
      });
    }
  },
  created() {
    //TODO: CHECK THIS: WHY THIS IS NOT WORKING?!
    //this.$vuetify.theme.dark = false;
  },
  async mounted() {
    let me = this;

    console.log("KRISTA Cloud in " + import.meta.env.MODE + " mode.");

    watch(
      () => this.$auth.state.loading,
      async loading => {
        if (loading === false && this.$auth.state.isAuthenticated === true) {
          console.log("Authenticated");
          this.$store.commit("app/set", ["isLoading", true]);

          this.connectHub();

          await me.$store.dispatch("status/load");
          await me.$store.dispatch("units/load");
          await me.$store.dispatch("events/load");
          await me.$store.dispatch("missions/load");
          await me.$store.dispatch("resources/load");
          await me.$store.dispatch("localStorage/load");
          this.$store.commit("app/set", ["isLoading", false]);
        }
      }
    );
  }
};
</script>
