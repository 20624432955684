<template>
  <v-card>
    <v-toolbar
      density="compact"
      color="primary"
      class="pl-5 text-uppercase font-weight-medium"
    >
      <v-icon size="small" class="mr-2">mdi-bell</v-icon>
      Tehtävät
      <v-spacer></v-spacer>
      <v-text-field
        style="max-width: 200px"
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        single-line
        hide-details
        density="compact"
      ></v-text-field>
      <template v-if="canAdd">
        <v-spacer />
        <MissionCreator />
      </template>
    </v-toolbar>

    <MissionEditor
      v-if="canDelete"
      :active="editDialog"
      @closed="editDialog = false"
    />
    <v-data-table
      :items="missions"
      :headers="headers"
      hide-default-footer
      item-key="id"
      return-object
      disable-pagination
      density="compact"
      :search="search"
      :custom-filter="filter"
      :loading="isLoading"
      loading-text="Tietoja haetaan..."
    >
      <template v-slot:item.units="{ item }">
        {{ unitList(item.units) }}
      </template>

      <template v-slot:item.time="{ item }">
        {{ formattedDateTime(item.time) }}
      </template>

      <template v-slot:item.details="{ item }">
        {{
          item.details && 0 !== item.details.length
            ? item.details
            : "Ei lisätietoja"
        }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          color="primary"
          v-if="canDelete"
          class="mr-2"
          @click="editMission(item.id)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="canDelete"
          color="primary"
          @click="deleteMission(item.id)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>
<style lang="sass" scoped></style>
<script>
// TODO: Chechk functionality and styling with other cases when we get those
import moment from "moment";
import debug from "debug";
import MissionCreator from "@/views/MissionCreator.vue";
import MissionEditor from "@/views/MissionEditor.vue";

export default {
  props: {
    isLoading: Boolean,
    canDelete: Boolean,
    canAdd: Boolean,
    dataUpdated: Function,
    noTitle: Boolean
  },
  components: {
    MissionCreator,
    MissionEditor
  },
  data: () => ({
    editDialog: false,
    search: "",
    headers: [
      { title: "Yksiköt", value: "units", sortable: false },
      { title: "Aika", value: "time", sortable: false, filterable: false },
      { title: "Tehtävä", value: "code", sortable: false, filterable: false },
      {
        title: "Paikkakunta",
        value: "municipality",
        sortable: false,
        filterable: false
      },
      { title: "Osoite", value: "address", sortable: false, filterable: false },
      {
        title: "Lisätietoja",
        value: "details",
        sortable: false,
        filterable: false
      },
      { text: "", value: "actions", sortable: false, filterable: false }
    ]
  }),
  computed: {
    missions() {
      let me = this;
      return me.$store.getters["missions/missions"];
    }
  },
  methods: {
    log: debug("ColoredUnitCard"),
    filter(value, query, item) {
      debug("moi");
      console.log("value", value);
      console.log("query", query);
      console.log("item", item);
      if (query == null) return true;

      var unitString = item.value.units.map(u => u.name).join(", ");
      console.log("unitString", unitString);

      var unitMatch =
        unitString.toLocaleUpperCase().indexOf(query.toLocaleUpperCase()) !==
        -1;
      console.log("1");
      var addressMatch =
        item.value.address
          .toLocaleUpperCase()
          .indexOf(query.toLocaleUpperCase()) !== -1;
      console.log("2");
      var codeMatch =
        item.value.code
          .toLocaleUpperCase()
          .indexOf(query.toLocaleUpperCase()) !== -1;
      console.log("3");
      var municipalityMatch =
        item.value.municipality
          .toLocaleUpperCase()
          .indexOf(query.toLocaleUpperCase()) !== -1;
      console.log("4");
      var detailsMatch =
        item.value.details != null &&
        item.value.details
          .toLocaleUpperCase()
          .indexOf(query.toLocaleUpperCase()) !== -1;
      console.log("5");
      return (
        value != null &&
        (unitMatch ||
          addressMatch ||
          codeMatch ||
          municipalityMatch ||
          detailsMatch)
      );
    },
    formattedDateTime(value) {
      return moment
        .utc(value)
        .local()
        .format("D.M. HH:mm");
    },
    unitList(units) {
      let listString = units.map(u => u.name).join(", ");
      return listString ? listString : "Ei yksiköitä";
    },
    async editMission(id) {
      let me = this;
      me.$debug("editMission", id);
      await me.$store.dispatch("missions/loadMissionToEditor", id);
      me.editDialog = true;
    },
    async deleteMission(id) {
      let me = this;
      try {
        let mission = me.missions.find(m => m.id === id);
        await me.$confirm(
          "Haluatko varmasti poistaa tehtävän " +
            mission.code +
            " - " +
            mission.address +
            ", " +
            mission.municipality +
            " ?",
          "Vahvista poisto",
          "Poista",
          "Peruuta"
        );
        await me.$store.dispatch("missions/deleteMission", id);
        me.$toast({
          type: "success",
          message:
            `Tehtävä '` +
            mission.code +
            " - " +
            mission.address +
            ", " +
            mission.municipality +
            `' on poistettu`
        });
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
      }
    }
  },
  watch: {
    // whenever question changes, this function will run
    missions: {
      handler(newMissions, oldMissions) {
        this.$debug(newMissions, oldMissions);
        if (newMissions.length > oldMissions.length) {
          this.$debug("uusi tuli");
          if (this.dataUpdated) {
            this.dataUpdated();
          }
        }
      },
      deep: true
    }
  }
};
</script>
